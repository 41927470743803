import React from 'react';
import {withPrefix} from 'gatsby';
import Helmet from 'react-helmet';
import queryString from 'query-string';

import {metadata} from '../../../config.js';
import {browserName, browserVersion, deviceType, isMobile, osName} from 'react-device-detect';
import HeaderWrapper from '../header';
import FooterWrapper from '../footer';

import {
  delayMultiplicationFactor,
  googleScriptLoadingDelay,
  isBrowser,
  sendEventToDataStream,
  setCookie,
  setStreamData
} from '../../utils/articleUtil';
import consts from '../../constants/consts';

import SubscribePopupWrapper from '../subscribe/SubscribePopup';
import {URL} from '../../constants/urls';
import {FloatingBanner, FloatingBannerSmall, track} from '@cg-squad/ui-components';
import mixpanel from '../../constants/mixpanel';
import StorageService from '../../utils/StorageService';
import {addTimeout, cancelAlltimeout} from '../../utils/timeManager.js';

class Layout extends React.Component {
  constructor (props) {
    super(props);

    const categoryPaths = this.setCategoryPaths(false);

    this.state = {
      categoryPath: categoryPaths?.categoryPath,
      subCategoryPath: categoryPaths?.subCategoryPath,
      timeouts: [],
      timeoutMultiplier: 1,
      isSubscribeOpen: false,
      propsIsSubscribeOpen: undefined,
      showMarketingCheckbox: false,
      isScrolling: false
    };
  }

  static getDerivedStateFromProps (props, current_state) {
    if (current_state.propsIsSubscribeOpen !== props.isSubscribeOpen) {
      return {
        isSubscribeOpen: props.isSubscribeOpen,
        propsIsSubscribeOpen: props.isSubscribeOpen
      };
    }
    return null;
  }

  addCustomGAEvents () {
    addTimeout(() => {
      window.dataLayer = window.dataLayer || [];
      const eventData = {
        'event': 'pageview',
        'hostname': window.location.hostname,
        'url': window.location.href,
        'page_Path': window.location.pathname
      };
      if (window.streamData && window.streamData.author) {
        eventData.author = window.streamData.author;
      }
      if (window.streamData && window.streamData.article) {
        eventData.page_title = window.streamData.article;
      }
      window.dataLayer.push(eventData);
    }, 100);
  }

  componentDidMount () {
    this.setCategoryPaths();

    window.openSubscribePopup = () => {
      this.onSubscriptionPopupStateChange(true);
    };

    const headElement = document.getElementsByTagName('head')[0];

    const _this = this;
    const timeoutMultiplier = delayMultiplicationFactor(isMobile);
    this.setState({timeoutMultiplier});

    addTimeout(() => {
      this.addAccessibilityScript();
    }, consts.SCRIPT_LOADING_TIME_WAIT * (4.5 + timeoutMultiplier));

    /*addTimeout(() => {
      this.addGetMetaSurveyScript()
    }, 30000);*/

    const popupSmartTimout = googleScriptLoadingDelay() > 1 ? (isMobile ? 3000 : 2000) : 100;
    addTimeout(() => {
      this.addPopupsmartScript();
    }, popupSmartTimout);

    addTimeout(() => {

      if (!document.querySelector('script[src*="gtm"]')) {
        const gtmScript = document.createElement('script');
        gtmScript.src = withPrefix(process.env.GATSBY_DOMAIN === '.co.uk' ? 'gtm.js' : 'dot-com-gtm.js');
        gtmScript.defer = true;
        headElement.appendChild(gtmScript);
        gtmScript.onload = this.addCustomGAEvents;
      } else {
        this.addCustomGAEvents();
      }

      if (!document.querySelector('script[src*="gpt.js"]')) {
        _this.addGPTScript();
      }

      _this.addAffiliateTrackingScript();
    }, googleScriptLoadingDelay() * timeoutMultiplier);

    addTimeout(() => {
      let showGsi = true;
      if (isBrowser() && window.location && window.location.search) {
        const query = queryString.parse(window.location.search);
        if (query.subscribe) {
          showGsi = false;
          this.onSubscriptionPopupStateChange(query.subscribe === 'true');
        }
      }
    }, consts.SCRIPT_LOADING_TIME_WAIT * timeoutMultiplier);

    setStreamData({
      deviceType,
      browserName,
      browserVersion,
      osName
    });

    try {
      let location = StorageService.getLocal('ag_location');
      if (location) {
        setStreamData({
          country: location.country.code,
          region: location.region.name,
          city: location.city,
          postal: location.postal,
          latitude: location.latitude,
          longitude: location.longitude
        });
      } else {
        addTimeout(() => {
          fetch(`${URL.SERVER}/geo/locationData`)
            .then(x => x.json())
            .then(response => {
              location = response;
              StorageService.setLocal('ag_location', response);
              setCookie('country', response.country.code);
              setStreamData({
                country: location.country.code,
                region: location.region.name,
                city: location.city,
                postal: location.postal,
                latitude: location.latitude,
                longitude: location.longitude
              });
            })
            .catch(error => {
              console.error('Unexpected error', error);
            });
        }, consts.SCRIPT_LOADING_TIME_WAIT * (timeoutMultiplier));
      }
    } catch (e) {
      console.log(e);
    }

    addTimeout(() => {
      sendEventToDataStream();
    }, (consts.SCRIPT_LOADING_TIME_WAIT * (timeoutMultiplier)) + 500);

    /*trackLinks('.affiliate-link', mixpanel.MIXPANEL_CLICK_EVENT.AFFILIATE);*/
  }

  addGPTScript () {
    const headElement = document.getElementsByTagName('head')[0];
    const adScript = document.createElement('script');
    adScript.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
    adScript.defer = true;
    headElement.appendChild(adScript);
  }

  setCategoryPaths = (setState = true) => {
    if (this.state?.categoryPath) {
      return;
    }
    let pathName = this.props.relativePath;
    let obj;

    if (pathName) {
      const pathSegments = pathName.split('/');
      obj = {
        categoryPath: pathSegments[0],
        subCategoryPath: pathSegments[1]
      };
    } else if (isBrowser()) {
      pathName = window.location.pathname;
      const pathSegments = pathName.split('/');
      obj = {
        categoryPath: pathSegments[1],
        subCategoryPath: pathSegments[2]
      };
    }

    if (setState) {
      this.setState(obj);
    } else {
      return obj;
    }
  };

  addAccessibilityScript () {
    const accessibilityOptions = {
      animations: {buttons: false},
      modules: {
        increaseText: true,
        decreaseText: true,
        invertColors: [true / false],
        increaseTextSpacing: false,
        decreaseTextSpacing: false,
        grayHues: true,
        underlineLinks: false,
        bigCursor: true,
        readingGuide: true,
        textToSpeech: true,
        speechToText: true
      },
      icon: {
        useEmojis: true
      }
    };
    const headElement = document.getElementsByTagName('head')[0];
    const _this = this;
    if (!document.querySelector('script[src*="accessibility.min.js"]')) {
      const script = document.createElement('script');
      script.src = `${withPrefix('accessibility.min.js')}`;
      script.type = 'text/javascript';
      headElement.appendChild(script);
      script.onload = () => {
        addTimeout(() => {
          new Accessibility(accessibilityOptions);
        }, 1000);
      };
    }
  }

  addPopupsmartScript () {
    const headElement = document.getElementsByTagName('head')[0];
    const popupsmartScriptEle = document.querySelector('script[src*="popupsmart"]');

    if (popupsmartScriptEle) {
      popupsmartScriptEle.parentNode.removeChild(popupsmartScriptEle);
    }
    const popupsmartScript = document.createElement('script');
    popupsmartScript.src = 'https://cdn.popupsmart.com/bundle.js';
    popupsmartScript.async = true;
    popupsmartScript.defer = true;
    popupsmartScript.setAttribute('data-id', '35297');
    headElement.appendChild(popupsmartScript);
  }

  addAffiliateTrackingScript () {
    if (!this.props.pageType || this.props.pageType !== 'article') {
      return;
    }
    const weCanTrackScriptEle = document.querySelector('script[src*="wecantrack"]');

    if (weCanTrackScriptEle) {
      weCanTrackScriptEle.parentNode.removeChild(weCanTrackScriptEle);
    }
    const headElement = document.getElementsByTagName('head')[0];
    const weCanTrackScript = document.createElement('script');
    weCanTrackScript.src = `${withPrefix(`wecantrack.js`)}`;
    weCanTrackScript.defer = true;
    headElement.appendChild(weCanTrackScript);
    /*const affiliateTrackScriptEle = document.querySelector('script[src*="heylink.com"]')

    if (affiliateTrackScriptEle) {
      affiliateTrackScriptEle.parentNode.removeChild(affiliateTrackScriptEle);
    }
    const headElement = document.getElementsByTagName("head")[0];
    const affiliateTrackScript = document.createElement("script");
    affiliateTrackScript.src = `https://tag.heylink.com/${process.env.GATSBY_DOMAIN === '.co.uk' ? 'c288f5b1-c671-48d5-8a3a-2b3f626cdda3' : 'b4a2dc24-e607-4c27-8e60-9709b4673cda'}/script.js`;
    affiliateTrackScript.defer = true;
    headElement.appendChild(affiliateTrackScript);*/
  }

  componentWillUnmount () {
    cancelAlltimeout();
    try {
      window.popup_smart_bundle_loaded = undefined;
    } catch (e) {

    }
  }

  onSubscriptionPopupStateChange = (state) => {
    if (state) {
      try {
        window.ps.show({id: +process.env.GATSBY_POPUPSMART_SUBSCRIBE_POPUP_ID});
      } catch (e) {
        console.log(e);
      }
      track(mixpanel.MIXPANEL_SUBSCRIBE_FLOW.SUBSCRIBE_POPUP);
      this.setState({isSubscribeOpen: false});
    }

    //this.setState({isSubscribeOpen: state})
  };

  render () {
    return (
      <React.Fragment>
        <Helmet defaultTitle={metadata.defaultTitle} titleTemplate={metadata.titleTemplate}>
          {process.env.GATSBY_NOINDEX_NOFOLLOW === 'true' &&
            <meta name="robots" content="noindex,nofollow"/>}
          {this.props.preloadImage?.images?.fallback ?
            <link fetchPriority="high" rel="preload" as="image"
                  href={this.props.preloadImage.images.fallback.src}
                  imageSrcSet={this.props.preloadImage.images.fallback.srcSet}
                  imageSizes={this.props.preloadImage.images.fallback.sizes}/> :
            <link fetchPriority="high" rel="preload" as="image"
                  href={this.props.preloadImage}/>
          }
          <meta name="author" content={metadata.author}/>
          <meta name="description" content={metadata.description}/>
          <html lang="en"/>
          {/*<html lang={process.env.GATSBY_DOMAIN === '.com' ? 'en-US' : 'en-GB'}/>*/}
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
          <meta httpEquiv="Content-Language" content="en"/>
          <meta property={metadata.facebookId} content="Age-Times-102410988886246"/>
          <meta property={metadata.twitterId} content="Age_Times"/>
          <link rel="shortcut icon" type="image/png" href="/images/favicon.png"/>

          <noscript>
            {`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_DOMAIN === '.co.uk' ? 'GTM-K5K6TD4' : 'GTM-MPHZ3C5'}"
                            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
          </noscript>
          {process.env.GATSBY_DOMAIN === '.com' &&
            <meta name="fo-verify" content="e3b43c2d-42a7-417e-9e27-d5112102ec91"/>}
        </Helmet>
        <div id="outer-container">
          <HeaderWrapper path={this.props.path}
                         categoryPath={this.state.categoryPath}
                         subCategoryPath={this.state.subCategoryPath}
                         hideCategories={this.props.hideCategories}
                         onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
          {this.props.children}
          <div className="mx-auto border-t border-b my-8 py-4 max-w-[80%] lg:max-w-[448px]">
            <img loading="lazy" className="w-full" src="/images/logos/logo-light.svg"/>
          </div>
          <FooterWrapper/>
          <FloatingBanner className={'invisible'}
                          onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
          <FloatingBannerSmall className={'invisible'}
                               onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
          {this.state.isSubscribeOpen && <SubscribePopupWrapper onPopupClose={() => {
            this.props.onSubscriptionPopupStateChange && this.props.onSubscriptionPopupStateChange(false);
            this.setState({
              isSubscribeOpen: false
            });
          }}/>}
          <div id="special"/>
        </div>
      </React.Fragment>
    );
  }
}

export default Layout;
